<template>
  <Layout>
    <PageHeader :title="title1" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Member <span style="color: red">*</span></label
                >
                <multiselect
                  @search-change="fetchMemberList"
                  id="exp"
                  v-model="form.member_id"
                  :options="memberList"
                  :multiple="false"
                  track-by="member_id"
                  label="fnameAndLname"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.member_id.$error,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.member_id.required"
                  class="invalid-feedback"
                >
                  Member is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Total Amount <span style="color: red">*</span></label
                >
                <b-form-input
                  id="input-3"
                  placeholder="Enter Total Amount"
                  v-model="form.transaction_amt"
                  :class="{
                    'is-invalid': submitted && $v.form.transaction_amt.$error,
                  }"
                >
                </b-form-input>
                <div
                  v-if="submitted && !$v.form.transaction_amt.required"
                  class="invalid-feedback"
                >
                  total_amount is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-6"
              >
                <label style="font-weight: 500"
                  >Point Percentage <span style="color: red">*</span>
                </label>
                <b-form-input
                  id="input-2"
                  placeholder="Enter Point Percentage"
                  v-model="form.percentage"
                  type="number"
                  min="0"
                  :class="{
                    'is-invalid': submitted && $v.form.percentage.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.percentage.required"
                  class="invalid-feedback"
                >
                  percentage is required.
                </div>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-6"
              >
                <label style="font-weight: 500">Note</label>
                <b-form-textarea
                  id="message"
                  v-model="form.notes"
                  placeholder="Add note"
                  rows="4"
                >
                </b-form-textarea>
              </b-form-group>
              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  Add Points
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import WalletTransactionMixin from "../../../mixins/ModuleJs/wallet";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, WalletTransactionMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Points",
      items: [
        {
          text: "Back",
          href: "/wallet",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      percentage: { required },
      transaction_amt: { required },
      member_id: { required },
    },
  },
};
</script>
